<template>
	<main class="page-container">
		<!-- s: 상단 영역/검색 -->
		<section class="scomm-section">
			<h3 class="sr-only">검색창</h3>
			<div class="container">
				<div class="scomm-main-slogan flex-both align-items-end">
					<div class="flex-1">
						<p class="scomm-main-slogan__strong">
							소상공인 지원사업 <br />
							정보교류는 여기서!
						</p>
						<p class="scomm-main-slogan__desc">
							내가 참여하는 소상공인 지원사업에 대한 소식도 만나고,<br />
							다른 소상공인들과 다양한 정보를 나누며 함께 성장할 수 있어요
						</p>
					</div>
					<div class="flex-0">
						<a href="#" class="link-text">커뮤니티 운영정책</a>
					</div>
				</div>
				<div class="scomm-main-pic">
					<ol class="scomm-main-pic__list">
						<li>
							<strong>
								<em>1</em>
								<span>소상공인 지원사업 참여하기</span>
							</strong>
							<p>판판대로를 통해 진행하는 지원사업에 최종선정이 된 소상공인들이 참여할 수 있어요</p>
						</li>
						<li>
							<strong>
								<em>2</em>
								<span>커뮤니티 검색, 가입하기</span>
							</strong>
							<p>참여한 지원사업명으로 해당 커뮤니티를 검색하고 가입을 하면 담당자가 승인해드려요</p>
						</li>
						<li>
							<strong>
								<em>3</em>
								<span>마이페이지에서 이용하기</span>
							</strong>
							<p>가치삽시다 마이페이지를 통해 내가 참여하는 지원사업별 커뮤니티에서 지원사업에 대한 정보를 교류하세요</p>
						</li>
					</ol>
				</div>
				<div class="scomm-main-search">
					<form action="#">
						<fieldset>
							<legend class="sr-only">커뮤니티 검색</legend>
							<input type="search" class="scomm-main-search__input" placeholder="커뮤니티를 검색해 보세요!    ex) 상세페이지 제작지원" />
							<button type="submit" class="scomm-main-search__submit"><i class="icon-scomm-search"></i><span class="sr-only">검색</span></button>
						</fieldset>
					</form>
				</div>
			</div>
		</section>
		<!-- e: 상단 영역/검색 -->
		<!-- s: 조회 결과 -->
		<section class="scomm-section scomm-section--bg">
			<div class="container">
				<h3 class="sr-only">조회 결과</h3>
				<p class="scomm-result-desc">소상공인 커뮤니티 총 <strong>21</strong> 개</p>
				<!-- [DEV] 조회 결과가 없는 경우 -->
				<div class="no-results-found">
					<p class="text">등록된 커뮤니티가 없습니다</p>
				</div>
				<!-- scomm-card -->
				<article class="scomm-card">
					<a href="">
						<div class="scomm-card-item flex-both">
							<div class="flex-l">
								<span class="scomm-card-thumb">
									<img src="~@/assets/img/@tmp/notice_popup.png" alt="이미지 정보" />
								</span>
							</div>
							<div class="flex-r">
								<p class="scomm-card-path">2023 > 소상공인 > 온라인 역량강화 > 소상공인 인플루언서 교육> 소상공인인플루언서교육 지원 사업</p>
								<div class="scomm-card-item__text flex-both">
									<div class="flex-l">
										<h4 class="scomm-card-title">소상공인 상세페이지 제작지원</h4>
										<p class="scomm-card-desc">2023년 상반기 소상공인 상세페이지 제작지원 커뮤니티입니다</p>
									</div>
									<div class="flex-r">
										<dl class="scomm-card-info">
											<dt>문의처</dt>
											<dd>담당자(정) 김가치</dd>
										</dl>
										<dl class="scomm-card-info">
											<dt>유선 연락처</dt>
											<dd>02-667-9000</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</a>
				</article>
				<!-- //scomm-card -->
				<!-- scomm-card -->
				<article class="scomm-card">
					<a href="">
						<div class="scomm-card-item flex-both">
							<div class="flex-l">
								<span class="scomm-card-thumb">
									<img src="~@/assets/img/@tmp/notice_popup.png" alt="이미지 정보" />
								</span>
							</div>
							<div class="flex-r">
								<p class="scomm-card-path">2023 > 소상공인 > 온라인 역량강화 > 소상공인 인플루언서 교육> 소상공인인플루언서교육 지원 사업</p>
								<div class="scomm-card-item__text flex-both">
									<div class="flex-l">
										<h4 class="scomm-card-title">소상공인 상세페이지 제작지원</h4>
										<p class="scomm-card-desc">2023년 상반기 소상공인 상세페이지 제작지원 커뮤니티입니다</p>
									</div>
									<div class="flex-r">
										<dl class="scomm-card-info">
											<dt>문의처</dt>
											<dd>담당자(정) 김가치</dd>
										</dl>
										<dl class="scomm-card-info">
											<dt>유선 연락처</dt>
											<dd>02-667-9000</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</a>
				</article>
				<!-- //scomm-card -->
			</div>
		</section>
		<!-- e: 조회 결과 -->
		<!-- pagination -->
		<portal-pagination page-name="ScommunityMainSearch" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries"></portal-pagination>
		<!-- //pagination -->
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import PortalPagination from '../../../components/PortalPagination';

export default {
	name: 'ScommunityMain',
	components: {
		PortalPagination,
	},
	data: () => ({
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 8,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
