<template>
	<nav v-if="totalCount > 0 && pageNo > 0" class="page-navigation">
		<ul class="pagination">
			<li v-if="pageNo > 1" class="page-item">
				<router-link :to="{ name: pageName, query: { pageNo: 1, ...query } }" custom v-slot="{ href, navigate, isActive, isExactActive }">
					<a class="page-link" :href="href" @click="navigate"><i class="icon-pagination-first"></i></a>
				</router-link>
			</li>
			<li class="page-item" :class="{ disabled: startPageList - 1 === 0 }">
				<span v-if="startPageList - 1 === 0" class="page-link rounded-start"><i class="icon-pagination-prev"></i></span>
				<router-link v-else :to="{ name: pageName, query: { pageNo: startPageList - 1, ...query } }" custom v-slot="{ href, navigate, isActive, isExactActive }">
					<a class="page-link" :href="href" @click="navigate"><i class="icon-pagination-prev"></i></a>
				</router-link>
			</li>
			<li :key="idx" v-for="(num, idx) in numberRange" class="page-item" :class="{ active: pageNo === num }">
				<router-link :to="{ name: pageName, query: { pageNo: num, ...query } }" custom v-slot="{ href, navigate, isActive, isExactActive }">
					<a class="page-link" :href="pageNo === num ? 'javascript:' : href" @click="navigate">{{ num }}</a>
				</router-link>
			</li>
			<li class="page-item" :class="{ disabled: endPageList >= totalPage }">
				<span v-if="endPageList >= totalPage" class="page-link rounded-start"><i class="icon-pagination-next"></i></span>
				<!--        <a v-else class="page-link" href="#"><i class="icon-pagination-next"></i></a>-->
				<router-link v-else :to="{ name: pageName, query: { pageNo: endPageList + 1, ...query } }" custom v-slot="{ href, navigate, isActive, isExactActive }">
					<a class="page-link" :href="href" @click="navigate"><i class="icon-pagination-next"></i></a>
				</router-link>
			</li>
			<li v-if="pageNo < finalPageList" class="page-item">
				<router-link :to="{ name: pageName, query: { pageNo: finalPageList, ...query } }" custom v-slot="{ href, navigate, isActive, isExactActive }">
					<a class="page-link" :href="href" @click="navigate"><i class="icon-pagination-last"></i></a>
				</router-link>
			</li>
		</ul>
	</nav>
</template>
<script>
export default {
	name: 'Pagination',
	props: {
		pageName: String,
		pageListSize: {
			type: Number,
			default: 10,
		},
		totalCount: {
			type: Number,
			default: 0,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
		pageNo: {
			type: Number,
			default: 0,
		},
		query: {
			type: Object,
			default: {},
		},
	},
	computed: {
		totalPage() {
			return Math.ceil(this.totalCount / this.pageSize);
		},
		totalPageList() {
			
			
			return Math.ceil(this.totalPage / this.pageListSize);
		},
		pageList() {
			let pageList = Math.ceil(this.pageNo / this.pageListSize);
			if (pageList < 1) pageList = 1;
			if (pageList > this.totalPageList) pageList = this.totalPageList;
			return pageList;
		},
		startPageList() {
			let startPageList = (this.pageList - 1) * this.pageListSize + 1;
			if (startPageList < 1) startPageList = 1;
			return startPageList;
		},
		endPageList() {
			let endPageList = this.startPageList + this.pageListSize - 1;
			if (endPageList > this.totalPage) endPageList = this.totalPage;
			if (endPageList < 1) endPageList = 1;
			return endPageList;
		},
		finalPageList() {
			let finalPageList = Math.floor((this.totalCount + (this.pageSize - 1)) / this.pageSize);
			return finalPageList;
		},
		numberRange() {
			const range = [];
			for (let i = this.startPageList; i <= this.endPageList; i++) {
				range.push(i);
			}
			return range;
		},
	},
};
</script>
